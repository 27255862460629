'use strict';

class LoginController {
  constructor(Auth, $state, usSpinnerService, $timeout, Cart, $window, $rootScope) {
    this.usSpinnerService = usSpinnerService;
    this.isDisabled = false;
    this.user = {};
    this.errors = {};
    this.submitted = false;

    this.Auth = Auth;
    this.$state = $state;
    this.Cart = Cart;
    this.$rootScope = $rootScope;
    this.$window = $window;
    $timeout(()=> {
      this.stopSpin('spinner-1');
    })
  }

  login(form) {
    this.submitted = true;

    if (form.$valid) {
      this.startSpin('spinner-1');
      this.isDisabled = true;
      this.Auth.login({
        email: this.user.email,
        password: this.user.password
      })
        .then((user) => {
          // Logged in, redirect to home
          if (this.$rootScope.returnToState ) {
            this.$window.location.href = this.$rootScope.returnToState;
          } else {
            this.$state.go('main');
          }
        })
        .catch(err => {
          this.errors.other = err.message;
          this.stopSpin('spinner-1');
          this.isDisabled = false;
        });
    }
  }

  startSpin(spinner) {
    this.usSpinnerService.spin(spinner);
  }

  stopSpin(spinner) {
    this.usSpinnerService.stop(spinner);
  }
}

angular.module('windmanagerApp')
  .controller('LoginController', LoginController);
